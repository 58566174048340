import type { Namespace } from 'i18next';
import { UseTranslationOptions, useTranslation } from 'react-i18next';

const DEFAULT_NS = 'translation';

export function useAppTranslation(
  prefix?: string,
  namespace?: Namespace,
  options?: UseTranslationOptions<string>,
) {
  const { t, ...rest } = useTranslation(DEFAULT_NS || namespace, {
    ...options,
    keyPrefix: prefix,
  });

  return {
    t,
    ...rest,
  };
}
