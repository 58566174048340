import { styled } from '@mui/material/styles';
import { baseWhite } from '../../style/colors';
import { APP_HEADER_HEIGHT } from './../../constants/general';

interface Props {
  transparent?: boolean;
  isDashboard?: boolean;
}

export const MainContainer = styled('div')(
  ({ transparent = false, isDashboard = false }: Props) => ({
    position: 'relative',
    padding: isDashboard ? '0px' : '12px 24px',
    minHeight: `calc(100vh - ${APP_HEADER_HEIGHT}px - 16px - 50px)`,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: transparent ? '' : baseWhite,
  }),
);
