import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import StyledWrapper from './styledWrapper';

import { getEmployeeListFromCompany } from '../../features/employee/services/employee_service';
import {
  getEmployeeList,
  getEmployeeListError,
  getEmployeeListStatus,
} from '../../features/employee/slices/getEmployeeFromCompanySlice';
import { StatusEnum } from '../../features/types/shared/status';

import EditButton from '../shared/editButton';
import { ErrorContainer } from '../shared/error_container';
import Loader from '../shared/loader';
import StyledTableContainer from '../shared/styledTableContainer';

import { ROWS_PER_PAGE_OPTIONS } from '../../constants/general';

import getDataGridLocale from '../../helpers/getDataGridLocale';
import useErrorMessage from '../../hooks/useErrorMessage';

export default function EmployeeTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'employee_list_table',
  });

  const employeeList = useAppSelector(getEmployeeList);
  const employeeListStatus = useAppSelector(getEmployeeListStatus);
  const employeeListError = useAppSelector(getEmployeeListError);
  const errorMessage = useErrorMessage(employeeListError);
  const { companyId } = useParams();

  useEffect(() => {
    dispatch(getEmployeeListFromCompany(companyId ?? ''));
  }, [companyId, dispatch]);

  const employeeListTableColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'firstName',
        headerName: t('column_headers.first_name'),
        type: 'string',
        flex: 1,
        sortable: false,
      },
      {
        field: 'namePrefix',
        headerName: t('column_headers.name_prefix'),
        type: 'string',
        flex: 1,
        sortable: false,
      },
      {
        field: 'lastName',
        headerName: t('column_headers.last_name'),
        flex: 1,
        type: 'string',
        sortable: false,
      },
      {
        field: 'status',
        headerName: t('column_headers.status'),
        flex: 0.8,
        type: 'string',
        sortable: false,
        valueGetter: (__, row) =>
          row.disabled
            ? t('employee_status.disabled')
            : t('employee_status.active'),
      },
      {
        field: 'email',
        headerName: t('column_headers.email_address_business'),
        flex: 1,
        type: 'string',
      },
      {
        field: 'id',
        headerName: t('column_headers.edit'),
        sortable: false,
        disableColumnMenu: true,
        display: 'flex',
        cellClassName: 'edit-btn-cell',
        renderCell: params => {
          const onClick = () => {
            navigate(`editEmployee/${params.id}`);
          };

          return <EditButton onClick={onClick} />;
        },
      },
    ],
    [navigate, t],
  );

  const isLoading =
    employeeListStatus === StatusEnum.PENDING ||
    employeeListStatus === StatusEnum.INITIAL;

  if (isLoading) {
    return <Loader />;
  }

  if (employeeListStatus === StatusEnum.LOADED) {
    return (
      <StyledWrapper>
        {employeeList.length > 0 && (
          <StyledTableContainer>
            <DataGrid
              localeText={getDataGridLocale(i18n.language)}
              rows={employeeList}
              columns={employeeListTableColumns}
              pagination
              initialState={{
                sorting: {
                  sortModel: [{ field: 'email', sort: 'desc' }],
                },
              }}
              pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
              disableColumnSelector
            />
          </StyledTableContainer>
        )}
      </StyledWrapper>
    );
  }

  if (employeeListStatus === StatusEnum.ERROR) {
    return <ErrorContainer>{errorMessage}</ErrorContainer>;
  }

  return <ErrorContainer>{t('error')}</ErrorContainer>;
}
