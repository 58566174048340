import { enUS, nlNL } from '@mui/x-data-grid/locales';

import { LocaleId } from '../constants/general';

export default function getDataGridLocale(loc: string) {
  const locales = {
    [LocaleId.EN]: enUS,
    [LocaleId.NL]: nlNL,
  };

  return locales[loc].components?.MuiDataGrid.defaultProps.localeText;
}
