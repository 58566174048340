import { MainContainer } from '../shared/main_container';
import ActionRow from './action_row';
import UserManagementTable from './company_table/userManagementTable';

export default function UserManagementDashboard() {
  return (
    <MainContainer transparent isDashboard>
      <ActionRow />
      <UserManagementTable />
    </MainContainer>
  );
}
